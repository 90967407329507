/*
| Developed by Starton
| Filename : ProjectCard.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import { Box, CardContent, Typography, Chip, Stack } from "@mui/material";
import Link from "next/link";
import Image from "next/image";
import { Utils } from "@starton/utils";
import { Project, ProjectTagMapping } from "@/modules/project/api/project.dto";
import { UrlsConfig } from "@/config/urls.config";
import { InteractiveCard } from "@/components/common";

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
  project: Project;
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ProjectCard = ({ project }: Props) => {
  return (
    <InteractiveCard
      component={Link}
      href={UrlsConfig.webapp.project.details(project.slug)}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2, mb: 2 }}>
          <Box
            sx={{
              position: "relative",
              width: 56,
              height: 56,
              borderRadius: 1,
              overflow: "hidden",
              flexShrink: 0,
              bgcolor: (theme) => theme.palette.background.paper,
            }}
          >
            {project.logo?.original?.url ? (
              <Image
                src={project.logo.original.url}
                alt={project.name}
                fill
                style={{ objectFit: "cover" }}
                sizes="56px"
              />
            ) : project.logo?.url ? (
              <Image
                src={project.logo.url}
                alt={project.name}
                fill
                style={{ objectFit: "cover" }}
                sizes="56px"
              />
            ) : null}
          </Box>
          <Stack spacing={1} sx={{ minWidth: 0, flex: 1 }}>
            <Typography variant="h4" component="h2" noWrap>
              {project.name}
            </Typography>
            {project.tags ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{ flexWrap: "wrap", gap: 1 }}
              >
                {project.tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={ProjectTagMapping[tag]}
                    size="small"
                    variant="outlined"
                  />
                ))}
              </Stack>
            ) : null}
          </Stack>
        </Box>

        {project.description ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {Utils.string.truncate(project.description, 100)}
          </Typography>
        ) : null}
      </CardContent>

      {/* <CardActions sx={{ justifyContent: 'space-between', gap: 1 }}>
				<Stack direction="row" spacing={1}>
					{project.websiteUrl ? (
						<IconButton component={Link} href={project.websiteUrl} target="_blank" size="small">
							<WebsiteIcon fontSize="small" />
						</IconButton>
					) : null}
					{project.githubUrl ? (
						<IconButton component={Link} href={project.githubUrl} target="_blank" size="small">
							<GithubIcon fontSize="small" />
						</IconButton>
					) : null}
					{project.twitterUrl ? (
						<IconButton component={Link} href={project.twitterUrl} target="_blank" size="small">
							<TwitterIcon fontSize="small" />
						</IconButton>
					) : null}
					{project.linkedinUrl ? (
						<IconButton component={Link} href={project.linkedinUrl} target="_blank" size="small">
							<LinkedInIcon fontSize="small" />
						</IconButton>
					) : null}
					{project.telegramUrl ? (
						<IconButton component={Link} href={project.telegramUrl} target="_blank" size="small">
							<TelegramIcon fontSize="small" />
						</IconButton>
					) : null}
				</Stack>
				<Stack direction="row" spacing={1}>
					{session?.id === project.userId || session?.isAdmin ? (
						<Chip
							label={ProjectStatusMapping[project.status].label}
							size="small"
							color={project.status === 'APPROVED' ? 'success' : 'warning'}
						/>
					) : null}
					{session?.id === project.userId ? <Chip label="Mine" size="small" color="primary" /> : null}
				</Stack>
			</CardActions> */}
    </InteractiveCard>
  );
};
