/*
| Developed by Starton
| Filename : project.hooks.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import useSWR, { SWRConfiguration } from "swr";
import { ProjectsIndexParams } from "@/modules/project/api/project.dto";
import { ProjectEndpoint } from "@/modules/project/api/project.endpoint";

/**
 * Get paginated list of projects
 * @param params Pagination and filters
 * @returns Paginated list of projects
 */
export const useProjectsIndex = (params?: ProjectsIndexParams) => {
  const { fetcher, uri } = ProjectEndpoint.index({ params });
  return useSWR(uri, fetcher);
};

/**
 * Get a project by its slug
 * @param projectSlug Project slug
 * @param options SWR configuration
 * @returns Project
 */
export const useProjectsShow = (
  projectSlug: string | undefined,
  options?: SWRConfiguration,
) => {
  const { fetcher, uri } = ProjectEndpoint.show(projectSlug as string);
  return useSWR(uri ? uri : null, fetcher, options);
};

/**
 * Get related projects
 * @param projectSlug Project slug
 * @returns Related projects
 */
export const useProjectsRelated = (projectSlug: string | undefined) => {
  const { fetcher, uri } = ProjectEndpoint.related(projectSlug as string);
  return useSWR(uri ? uri : null, fetcher);
};
